import { LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { getProjectStatus } from '../../../../utils/common';
import Button from '../../../../components/button';
import { ReactComponent as RestartIcon } from '../../../../icons/restart.svg';
import { ReactComponent as StopIcon } from '../../../../icons/pauseIcon.svg';
import { useParams } from 'react-router-dom';
import { useRestartSearchMutation, useStopSearchMutation } from '../../../../services';
import { useCallback, useMemo, useState } from 'react';
import {  createNotification } from '../../../../utils/notifications';
import { RESTARTABLE_REPORT_STATES } from '../../../../utils/constant';

export const ReportStatus = () => {
  const report = useSelector((state) => state?.organizations?.selectedReport);
  const [isDisabled, setIsDisabled] = useState(false);
  const { id } = useParams();

  const [restartSearch] = useRestartSearchMutation();
  const [stopSearch] = useStopSearchMutation();

  const temporarilyDisabled = () => {
    setIsDisabled(true);

    setTimeout(() => {
      setIsDisabled(false);
    }, 5000);
  };

  const handleStopProjectSearch = useCallback(async () => {
    temporarilyDisabled();
    await stopSearch(id)
      .unwrap()
      .then(() => {
        createNotification('Поиск проекта остановлен');
      });
  }, [id, stopSearch]);

  const handleRestartProjectSearch = useCallback(async () => {
    temporarilyDisabled();
    await restartSearch(id)
      .unwrap()
      .then(() => {
        createNotification('Поиск проекта перезапущен');
      });
  }, [id, restartSearch]);

  const progress = useMemo(
    () => Math.round(report?.total_count ? (report?.success_product_count / report?.total_count) * 100 : 0),
    [report?.success_product_count, report?.total_count]
  );

  if (!report) {
    return null;
  }
  if (report?.total_count === 0) {
    return null;
  }
  return (
    <div className="tw-w-full tw-bg-white tw-flex tw-flex-col tw-items-start tw-pt-3 tw-pl-4 tw-pr-[34px] tw-pb-[17px] tw-rounded-lg tw-gap-[10px]">
      <p className="tw-text-sm tw-text-[#191919] tw-font-semibold tw-leading-[18px]">Статус отчета</p>
      <div className="tw-flex tw-flex-row tw-justify-between tw-w-full tw-items-center tw-gap-[31px]">
        <div className="tw-flex tw-flex-row tw-divide-x tw-divide-[#E6E6E6] tw-h-[32px] tw-items-center">
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-[6px] tw-pr-[18px]">
            <p className=" tw-text-[#4C4C4C] tw-text-sm tw-leading-[18px]">
              Найдено товаров <span className="tw-text-[#191919] tw-font-medium">{report?.success_product_count}</span>{' '}
              из <span className="tw-text-[#191919] tw-font-medium">{report?.total_count}</span>
            </p>
            <LinearProgress
              value={progress}
              variant="determinate"
              className={`tw-w-full tw-bg-[#EEEEEE] tw-min-w-[202px]`}
              sx={{
                color: '#000',
                borderRadius: '50px',
                span: {
                  backgroundColor: progress >= 100 ? '#49CA4E' : '#FEB945',
                },
              }}
            />
          </div>
          <div className="tw-px-[18px] tw-flex tw-flex-row tw-items-center ">
            {getProjectStatus({
              report_status: report?.status,
            })}
          </div>
          <div className="tw-pl-[18px] tw-flex tw-flex-row tw-items-center">
            <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px]">
              Количество товаров{' '}
              <span className="tw-text-[#191919] tw-font-semibold tw-text-sm tw-leading-[16px]">
                {report.total_count ?? 0}
              </span>
            </p>
          </div>
        </div>
        {!RESTARTABLE_REPORT_STATES.includes(report?.status) ? (
          <Button
            onClick={handleStopProjectSearch}
            disabled={isDisabled}
            className="tw-flex tw-flex-row tw-items-center tw-gap-[2px] tw-w-fit tw-bg-[#134B98] tw-py-[11px] tw-px-4 tw-rounded-lg tw-text-sm tw-leading-[18px] tw-font-semibold disabled:tw-bg-[#8CA6C1]"
          >
            <StopIcon className="tw-fill-white" />
            Остановить поиск
          </Button>
        ) : (
          <Button
            onClick={handleRestartProjectSearch}
            disabled={isDisabled}
            className="tw-flex tw-flex-row tw-items-center tw-gap-[2px] tw-w-fit tw-bg-[#134B98] tw-py-[11px] tw-px-4 tw-rounded-lg tw-text-sm tw-leading-[18px] tw-font-semibold disabled:tw-bg-[#8CA6C1]"
          >
            <RestartIcon className="tw-fill-white" />
            Перезапустить поиск
          </Button>
        )}
      </div>
    </div>
  );
};
