import { request } from '../utils/request';
import { Api } from './api-client';

export const adminAPIs = {
  addUser: (body) =>
    request('/api/admin/users', {
      method: 'POST',
      body: body,
    }),
  storageGetUsers: (limit = 10, page_num = 1, query  = '') =>
    request(`/api/admin/storage/users?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  storageGetStatus: (body) =>
    request(`/api/admin/storage/toggle-cleanup`, {
      method: 'POST',
      body: body,
    }),
  editUser: (body) =>
    request('/api/admin/users', {
      method: 'PATCH',
      body: body,
    }),
  deleteUser: (id) =>
    request(`/api/admin/users/${id}`, {
      method: 'DELETE',
    }),
  getUsers: (limit = 10, page_num = 1, query = '') =>
    request(`/api/admin/users?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getOrganization: (limit = 10, page_num = 1, query = '') =>
    request(`/api/admin/organizations?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getStorageOrganizations: (limit = 10, page_num = 1, query  = '') =>
    request(`/api/admin/storage/organizations?page_size=${limit}&page_num=${page_num}&query=${query}`, {
      method: 'GET'
    }),
  addOrganization: (body) =>
    request('/api/admin/organizations', {
      method: 'POST',
      body: body,
    }),
  deleteOrganization: (id) =>
    request(`/api/admin/organizations/${id}`, {
      method: 'DELETE',
    }),
  getOrganizationAccesses: (id) =>
    request(`/api/admin/accesses?organization_id=${id}`, {
      method: 'GET',
    }),
  deleteAccess: (id) =>
    request(`/api/admin/accesses/${id}`, {
      method: 'DELETE',
    }),
  createAccess: (body) =>
    request(`/api/admin/accesses`, {
      method: 'POST',
      body: body,
    }),
  updateAccess: (body) =>
    request(`/api/admin/accesses`, {
      method: 'PATCH',
      body: body,
    }),
  getPriceRequests: (page_size, page_num, query) =>
    request(`/api/admin/product_requests?page_size=${page_size}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getPriceRequest: (id) =>
    request(`/api/admin/product_requests/${id}`, {
      method: 'GET',
    }),
  getSupportRequests: (page_size, page_num, query) =>
    request(`/api/admin/tech_supports/technical_support?page_size=${page_size}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getSupportRequest: (id) =>
    request(`/api/admin/tech_supports/technical_support/${id}`, {
      method: 'GET',
    }),
  addSupportRequest: (body) =>
    request('/api/admin/tech_supports/technical_support', {
      method: 'POST',
      body: body,
    }),
  loginAs: (id) =>
    request('/api/login/login_as', {
      method: 'POST',
      body: {
        user_id: id,
      },
    }),
  getTechStatus: () => request('/api/admin/tech_supports/technical_support/get_status_problem', { method: 'GET' }),
  setTechStatus: (status) =>
    request('/api/admin/tech_supports/technical_support/set_status_problem', {
      method: 'POST',
      body: {
        status: status,
      },
    }),
  sendUserData: (body) =>
    request('/api/admin/users/send_login', {
      method: 'POST',
      body: body,
    }),
  adminProjectReload: (id) =>
    request(`/api/communications/refind/admin/${id}`, {
      method: 'GET',
    }),
};


//TODO: переписать запросы AdminAPI на RTK
export const newAdminAPIs = Api.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotifications: builder.query({
      query: (page_size = 10, page_num = 1, query="") => ({
        method: 'GET',
        url: `/api/admin/organizations/get_info_messages?page_size=${page_size}&page_num=${page_num}&query=${query}`,
      }),
      providesTags: [{type: 'listNotifications'}]
    }),
    createNotifications: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: '/api/admin/organizations/info_message',
        body: body,
      }),
      invalidatesTags: [{type: 'listNotifications'}]
    }),
    removeNotification: builder.mutation({
      query: (message_id) => ({
        method: 'DELETE',
        url: `/api/admin/organizations/delete_info_message/${message_id}`,
      }),
      invalidatesTags: [{type: 'listNotifications'}]
    }),
    editNotification: builder.mutation({
      query: ({message_id, body}) => ({
        method: 'PUT',
        url: `/api/admin/organizations/edit_info_message/${message_id}`,
        body: body,
      }),
      invalidatesTags: [{type: 'listNotifications'}, {type: 'detailNotification'}]
    }),
    getDetailNotification: builder.query({
      query: (message_id) => ({
        method: 'GET',
        url: `/api/admin/organizations/get_info_message/${message_id}`,
      }),
      providesTags: [{type: 'detailNotification'}]
    }),
    getAutoScreenshotCleanupStatus: builder.query({
      query: () => ({
        method: 'GET',
        url: '/api/admin/storage/get_cleanup_status',
      }),
    }),
    toggleAutoScreenshotCleanupStatus: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: '/api/admin/storage/toggle-cleanup',
        body: body,
      })
    }),
  }),
});

export const {
  useGetAllNotificationsQuery,
  useCreateNotificationsMutation,
  useRemoveNotificationMutation,
  useEditNotificationMutation,
  useGetDetailNotificationQuery,
  useGetAutoScreenshotCleanupStatusQuery,
  useToggleAutoScreenshotCleanupStatusMutation
} = newAdminAPIs;