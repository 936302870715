import { useSelector } from 'react-redux';
import RegionSelector from '../../../../components/region-selector';
import { TotalPriceCard } from './TotalPriceCard';
import { createErrorNotification, createNotification } from '../../../../utils/notifications';
import { useUpdateAddressMutation, useUpdateReportMutation } from '../../../../services';
import { useCallback } from 'react';

export const SidePanel = ({ isProjectEmpty, report_id }) => {
  const report = useSelector((state) => state?.organizations?.selectedReport);
  const regions = useSelector((state) => state.auth.regions);

  const [updateReport] = useUpdateReportMutation();
  const [updateAddress] = useUpdateAddressMutation();

  const handleChangeRegionId = useCallback(
    async (region_name) => {
      if (region_name === null) return;
      const region = regions.find((region) => region.name === region_name);
      if (!region) return;
      const body = {
        region_id: region.region_id,
        status: 'processing',
      };
      await updateReport({ report_id: report.id, body })
        .unwrap()
        .then(() => createNotification('Регион доставки изменён'))
        .catch((err) => {
          console.error(err);
          createErrorNotification('Произошла ошибка при смене региона');
        });
    },
    [regions, report?.id, updateReport]
  );

  const handleAddressChange = useCallback(
    async (address, postalCode) => {
      try {
        await updateAddress({ report_id: report.id, address, postalCode })
          .unwrap()
          .then(() => {
            createNotification('Адрес доставки изменён');
          });
      } catch (err) {
        console.error(err);
        createErrorNotification('Произошла ошибка при изменении адреса доставки');
      }
    },
    [report?.id, updateAddress]
  );

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-min-w-[292px] ">
      <RegionSelector report={report} onChangeRegionId={handleChangeRegionId} />
      {!isProjectEmpty && <TotalPriceCard report={report} handleChangeAddress={handleAddressChange}/>}
    </div>
  );
};
