import classNames from 'classnames';
import Button from '../../../../components/button';
import DropdownMenu from '../../../../components/dropdown';
import { ReactComponent as DeleteIcon } from '../../../../icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../../../icons/saveIcon.svg';
import { ReactComponent as MoreIcon } from '../../../../icons/MoreIcon.svg';
import { ReactComponent as BlockIcon } from '../../../../icons/blockIcon.svg';
import { ReactComponent as PictureIcon } from '../../../../icons/pictureIcon.svg';
import { MenuItem } from '@mui/material';
import { ImageWithFallback } from '../../../../components/imageWithFallback';
import { useCallback } from 'react';
import { useDialog } from '../../../../providers/dialog.provider';
import { ImageViewer } from '../../../news/modals/ImageViewer';

export const SourceInfo = ({
  asTable,
  sourceImg,
  url,
  hostname,
  sourceScreenshot,
  onDeleteSource,
  onSaveSource,
  onBlockSource,
  title,
}) => {
  const dialog = useDialog();
  const showImageFullScreen = useCallback(() => {
    dialog.open(<ImageViewer src={sourceScreenshot} />);
  }, [dialog, sourceScreenshot]);
  return (
    <div
      className={classNames('tw-flex tw-flex-row tw-items-start tw-gap-[14px] tw-w-full', {
        'tw-self-start': asTable,
      })}
    >
      {!asTable && (
        <ImageWithFallback
          srcImg={sourceImg}
          fallbackSrc={'/images/placeholders/other.png'}
          className="tw-rounded-[4px] tw-max-h-[70px] tw-min-h-[70px] tw-max-w-[70px] tw-min-w-[70px] tw-aspect-square"
          loading="lazy"
          alt="source_img"
        />
      )}
      <div className="tw-flex tw-flex-col tw-w-full tw-gap-1">
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
          <a
            href={url || '#'}
            className="tw-text-[#134B98] tw-text-sm tw-font-medium tw-leading-[18px] tw-cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            {hostname}
          </a>
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
            {/* <Button variant="text" className="hover:tw-bg-transparent tw-p-0 tw-w-[24px] tw-h-[24px]">
                <RestartIcon className="tw-fill-[#666666] tw-w-[24px] tw-h-[24px]" />
              </Button> */}
            {sourceScreenshot && (
              <Button variant="text" onClick={showImageFullScreen} className="tw-w-fit tw-p-0 hover:tw-bg-transparent">
                <PictureIcon />
              </Button>
            )}
            <Button
              onClick={onDeleteSource}
              variant="text"
              className="hover:tw-bg-transparent tw-p-0 tw-w-[20px] tw-h-[20px]"
            >
              <DeleteIcon className="tw-fill-[#666666] tw-w-[20px] tw-h-[20px]" />
            </Button>
            <DropdownMenu
              buttonChildren={
                <>
                  <MoreIcon />
                </>
              }
              btnProps={{
                className: 'tw-p-0 tw-w-[fit-content] hover:tw-bg-transparent',
                variant: 'text',
              }}
            >
              {/* <MenuItem
                  className="hover:tw-bg-transparent tw-group hover:tw-text-[#134B98] tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-[#4C4C4C]"
                  onClick={handleCreateChatWithSupplier}
                  disableRipple
                >
                  <MailIcon className="tw-fill-[#4C4C4C] group-hover:tw-fill-[#134B98]" /> Написать поставщику
                </MenuItem> */}
              <MenuItem
                className="hover:tw-bg-transparent tw-group hover:tw-text-[#134B98] tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-[#4C4C4C]"
                onClick={onSaveSource}
                disableRipple
              >
                <SaveIcon className="tw-fill-[#4C4C4C] group-hover:tw-fill-[#134B98]" /> Сохранить
              </MenuItem>
              <MenuItem
                className="hover:tw-bg-transparent tw-group hover:tw-text-[#134B98] tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-[#4C4C4C]"
                onClick={onBlockSource}
                disableRipple
              >
                <BlockIcon className="tw-fill-[#4C4C4C] group-hover:tw-fill-[#134B98]" /> Удалить и заблокировать
              </MenuItem>
            </DropdownMenu>
          </div>
        </div>
        <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-max-w-[487px]">{title}</p>
      </div>
    </div>
  );
};
