export const EVENTS = {
  OPEN_CONFIRM_MODAL: 'OPEN_CONFIRM_MODAL',
  RELOAD_PRODUCTS: 'RELOAD_PRODUCTS',
  SHOW_ARCHIVE_SOURCE: 'SHOW_ARCHIVE_SOURCE',
  OPEN_SIDEBAR: 'OPEN_SIDEBAR',
  OPEN_TECHNICAL_SUPPORT: 'OPEN_TECHNICAL_SUPPORT',
  OPEN_DIALOG: 'OPEN_DIALOG',
};

export const STANDARD_DATE_FORMAT = 'DD-MM-YYYY';

export const SOURCE_TYPES_VALUE = {
  YANDEX_SEARCH: 'yandex_search',
  ENGINE_SEARCH: 'engine_search',
  ZAKUPKI: 'zakupki',
  UNKNOWN_PRICE: 'unknown_price',
};

export const SOURCE_TYPES = {
  open_sources: 'Открытые источники',
  zakupki: 'Государственные контракты',
  suppliers: 'Поставщики',
};

export const FREQUENCY_OPTIONS_LABEL = {
  mondays: 'По понедельникам',
  tuesdays: 'По вторникам',
  wednesdays: 'По средам',
  thursdays: 'По четвергам',
  fridays: 'По пятницам',
  saturdays: 'По субботам',
  sundays: 'По воскресеньям',
  everyday: 'Каждый день',
};

export const USER_ROLE_OPTIONS = [
  { label: 'Юзер', value: 'user' },
  { label: 'Админ', value: 'admin' },
];

export const IMAGES_CATEGORIES = [
  'simple-cart',
  'Авто',
  'Аптека',
  'Бытовая техника',
  'Бытовая химия',
  'Дача и сад',
  'Детские товары',
  'Для школы и офиса',
  'Дом',
  'Здравоохранение',
  'Зоотовары',
  'Канцелярские товары',
  'Компьютеры',
  'Красота и гигиена',
  'Мебель',
  'Оборудование',
  'Одежда и обувь',
  'Оптика',
  'Продукты питания',
  'Спорт и отдых',
  'Строительство и ремонт',
  'Товары для взрослых',
  'Транспорт',
  'Хобби и творчество',
  'Хозяйственный инвентарь',
  'Цветы',
  'Цифровые товары',
  'Электроника',
  'Ювелирные украшения',
];

export const UNIT_OPTIONS = Object.freeze([
  { value: 'шт', label: 'шт' },
  { value: 'кг', label: 'кг' },
  { value: 'л', label: 'л' },
]);

export const REPORT_STATUS = Object.freeze({
  INITIAL: 'initial',
  PROCESSING: 'processing',
  FINISHED: 'finished',
  FAILED: 'failed',
  STOPPED: 'stopped',
  ALL_STOPPED: 'all_stopped',
  OPTIMIZED: 'optimized',
});

export const PRODUCT_STATUS = Object.freeze({
  INITIAL: 'initial',
  SEARCHING: 'searching',
  PARSING: 'parsing',
  FAILED: 'failed',
  FINISHED: 'finished',
  CANCELED: 'canceled',
  FAILED_SEARCHER: 'failed_searcher',
});

export const DELIVERY_SERVICES = Object.freeze({
  1: 'СДЕК',
  2: 'Почта России',
  3: 'DPD',
  4: 'ПЭК',
  5: 'ЖелДорЭкспедиция',
  6: 'ГК «Деловые линии»',
  7: 'Транспортная компания «КИТ»',
});

export const CALCULATED_DIMENSHIONS_FROM = Object.freeze({
  source: 'из источника',
  default: 'по умолчанию',
  user_data: 'введенными вами данными',
  catalogue: 'из справочника габаритов',
});

export const PRODUCT_LOADING_STATES = Object.freeze([
  PRODUCT_STATUS.PARSING,
  PRODUCT_STATUS.INITIAL,
  PRODUCT_STATUS.SEARCHING,
]);

export const RESTARTABLE_REPORT_STATES = Object.freeze([
  REPORT_STATUS.FINISHED,
  REPORT_STATUS.STOPPED,
  REPORT_STATUS.ALL_STOPPED,
]);

export const EXCEL_MIME_TYPES = Object.freeze([
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]);

export const FILTER_PRESET_LABELS = {
  NAME_DESC: 'Название (по убыванию)',
  NAME_ASC: 'Название (по возрастанию)',
  CREATED_DESC: 'Порядок добавления (по убыванию)',
  CREATED_ASC: 'Порядок добавления (по возрастанию)',
  PRICE_DESC: 'Цена (по убыванию)',
  PRICE_ASC: 'Цена (по возрастанию)',
};

export const FILTER_PRESETS = Object.freeze({
  [FILTER_PRESET_LABELS.NAME_DESC]: {
    sort: {
      order: 'desc',
      field: 'name',
    },
  },
  [FILTER_PRESET_LABELS.NAME_ASC]: {
    sort: {
      order: 'asc',
      field: 'name',
    },
  },
  [FILTER_PRESET_LABELS.CREATED_DESC]: {
    sort: {
      order: 'desc',
      field: 'created_at',
    },
  },
  [FILTER_PRESET_LABELS.CREATED_ASC]: {
    sort: {
      order: 'asc',
      field: 'created_at',
    },
  },
  [FILTER_PRESET_LABELS.PRICE_DESC]: {
    sort: {
      order: 'desc',
      field: 'price',
    },
  },
  [FILTER_PRESET_LABELS.PRICE_ASC]: {
    sort: {
      order: 'asc',
      field: 'price',
    },
  },
});

export const BACKGROUNDCOLOR_BY_STATUS = Object.freeze({
  [REPORT_STATUS.STOPPED]: 'tw-bg-[#FFF7F7]',
  [REPORT_STATUS.ALL_STOPPED]: 'tw-bg-[#FFF7F7]',
  [REPORT_STATUS.FAILED]: 'tw-bg-[#FFF7F7]',
  [REPORT_STATUS.OPTIMIZED]: 'tw-bg-[#FFFBF5]',
  [REPORT_STATUS.PROCESSING]: 'tw-bg-[#FFFBF5]',
  [REPORT_STATUS.INITIAL]: 'tw-bg-[#FFFBF5]',
});

export const IMAGE_MIME_TYPES = Object.freeze(['image/png', 'image/jpeg']);

export const REPORT_SCREENSHOTS_STATUS = Object.freeze({
  INITIAL: 'initial',
  PROCESSING: 'processing',
  UPDATING: 'updating',
  FINISHED: 'finished',
});
