import { request } from '../utils/request';
import { Api, onQueryStartedErrorHandler } from './api-client';
import * as constants from '../store/constants/organizations';
import { createErrorNotification } from '../utils/notifications';

export const projectsAPIs = {
  getAllProjects: (organization_id, page_size = 10, page_num = 1, query = '') =>
    request(
      `/api/organizations/${organization_id}/projects?page_size=${page_size}&page_num=${page_num}&query=${query}`,
      {
        method: 'GET',
      }
    ),
  updateProject: (id, body) =>
    request(`/api/projects/${id}`, {
      method: 'PATCH',
      body: body,
    }),
  deleteProject: (id) =>
    request(`/api/projects/${id}`, {
      method: 'DELETE',
    }),
  createProject: (body) =>
    request(`/api/projects`, {
      method: 'POST',
      body: body,
    }),
  stopSearch: (project_id) =>
    request(`/api/communications/cancel/${project_id}`, {
      method: 'GET',
    }),
  restartSearch: (project_id) =>
    request(`/api/communications/refind/${project_id}`, {
      method: 'GET',
    }),
  incorrectDimensions: (body) =>
    request('/api/admin/incorrect_dimensions', {
      method: 'POST',
      body,
    }),
  getOptimizedProductsNames: (report_id, batch_id, page_num, page_size, signal) =>
    request(
      `/api/projects/${report_id}/filtered_name/${batch_id}?page_num=${page_num}&page_size=${page_size}`,
      {
        method: 'GET',
      },
      signal
    ),
  updateOptimizedProductName: (body) =>
    request(`/api/reports/update_filtered_name`, {
      method: 'POST',
      body,
    }),
  cancelFileUpload: (report_id, upload_batch_id) =>
    request(`/api/reports/${report_id}/cancel_upload/${upload_batch_id}`, {
      method: 'DELETE',
    }),
  beginFileUpload: (report_id, upload_batch_id) =>
    request(`/api/reports/${report_id}/start_search/${upload_batch_id}`, {
      method: 'GET',
    }),
};

export const newProjectAPI = Api.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({ report_id, page_size, page_num, sort_by, sort_order }) => ({
        url: `/api/reports/${report_id}/products`,
        params: { page_size, page_num, sort_by, sort_order },
      }),
      providesTags: [{ type: 'Products' }],
    }),
    onQueryStarted: (arg, { queryFulfilled }) => {
      onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении товаров');
    },
    getProjectReport: builder.query({
      query: ({ project_id }) => ({
        url: `/api/projects/${project_id}/reports`,
      }),
      providesTags: [{ type: 'Report' }],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch({ type: constants.ORGANIZATIONS__SET_SELECTED_REPORT, report: data?.reports?.[0] });
        } catch (err) {
          createErrorNotification('Произошла ошибка при получении отчёта проекта');
          console.error('Error updating report', err);
        }
      },
    }),
    uploadExcelFile: builder.mutation({
      query: ({ project_id, report_id, file }) => ({
        url: `/api/projects/${project_id}/reports/${report_id}`,
        method: 'PUT',
        body: file,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при загрузке файла');
      },
      invalidatesTags: [{ type: 'Report' }, { type: 'Products' }],
    }),
    stopSearch: builder.mutation({
      query: (project_id) => ({
        url: `/api/communications/cancel/${project_id}`,
        method: 'GET',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при остановке поиска проекта');
      },
      invalidatesTags: [{ type: 'Report' }, { type: 'Products' }],
    }),
    restartSearch: builder.mutation({
      query: (project_id) => ({
        url: `/api/communications/refind/${project_id}`,
        method: 'GET',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при перезапуске поиска проекта');
      },
      invalidatesTags: [{ type: 'Report' }, { type: 'Products' }],
    }),
    incorrectDimensions: builder.mutation({
      query: (body) => ({
        url: '/api/admin/incorrect_dimensions',
        method: 'POST',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при изменении габаритов товара');
      },
      invalidatesTags: [{ type: 'Products' }, { type: 'Report' }],
    }),
    getOptimizedProductsNames: builder.query({
      query: ({ report_id, batch_id, page_num, page_size = 10 }) => ({
        url: `/projects/${report_id}/filtered_name/${batch_id}?page_num=${page_num}&page_size=${page_size}`,
        method: 'GET',
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (curentCache, newItems, { arg }) => {
        curentCache.push(...newItems);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: [{ type: 'OptimizedNames' }],
    }),

    updateOptimizedProductName: builder.mutation({
      query: (body) => ({
        url: '/reports/update_filtered_name',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'OptimizedNames' }],
    }),
    cancelFileUpload: builder.mutation({
      query: ({ report_id, upload_batch_id }) => ({
        url: `/reports/${report_id}/cancel_upload/${upload_batch_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Products' }, { type: 'Report' }],
    }),
    beginFileUpload: builder.mutation({
      query: ({ report_id, upload_batch_id }) => ({
        url: `/reports/${report_id}/start_search/${upload_batch_id}`,
        method: 'GET',
      }),
      invalidatesTags: [{ type: 'Products' }, { type: 'Report' }],
    }),
    createProject: builder.mutation({
      query: (body) => ({
        url: '/api/projects',
        method: 'POST',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при создании проекта');
      },
      invalidatesTags: [{ type: 'Projects' }],
    }),
    deleteProject: builder.mutation({
      query: (id) => ({
        url: `/api/projects/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при удалении проекта');
      },
      invalidatesTags: [{ type: 'Projects' }],
    }),
    updateProject: builder.mutation({
      query: (id, body) => ({
        url: `/api/projects/${id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при обновлении проекта');
      },
      invalidatesTags: [{ type: 'Projects' }],
    }),
    getProjects: builder.query({
      query: ({ organization_id, page_size, page_num, query }) => ({
        url: `/api/organizations/${organization_id}/projects`,
        params: { page_size, page_num, query },
        method: 'GET',
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении проектов');
      },
      providesTags: [{ type: 'Projects' }],
    }),
    makeScreenshots: builder.query({
      query: ({ project_id, user_id }) => ({
        url: '/api/screenshots/download',
        method: 'POST',
        body: {
          project_id,
          user_id,
        },
        responseHandler: 'blob'
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductsQuery,
  useGetProjectReportQuery,
  useUploadExcelFileMutation,
  useBeginFileUploadMutation,
  useCancelFileUploadMutation,
  useGetOptimizedProductsNamesQuery,
  useIncorrectDimensionsMutation,
  useRestartSearchMutation,
  useStopSearchMutation,
  useUpdateOptimizedProductNameMutation,
  useGetProjectsQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useCreateProjectMutation,
  useLazyMakeScreenshotsQuery,
} = newProjectAPI;
