import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { newsAPIs } from '../../../services';
import { createErrorNotification } from '../../../utils/notifications';
import moment from 'moment/moment';
import Loader from '../../loader';


export default function NewsWidget(){
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getNews = () => {
      setLoading(true);
      newsAPIs.getNews(3, 1, "&sort_by=date&sort_order=desc").then((res) => {
          setNews((prev) => [...prev, ...res.data]);
        })
        .catch((err) => {
          createErrorNotification("Произошла ошибка при получении новостей");
          console.error(err);
        })
        .finally(() => {
        setLoading(false);
      });
    };
    getNews();
  }, []);
  return(
    <div className="tw-relative tw-z-20 tw-overflow-hidden tw-pt-4 tw-px-[24px] tw-bg-white tw-rounded-xl tw-h-full">
      <div className="tw-flex tw-items-center tw-mb-1">
        <h2 className="tw-font-semibold tw-text-2xl tw-leading-[28px]">Новости</h2>
        <Link
          to="/changelog"
          className="tw-text-[#0844b4] hover:tw-text-[#0A50D4] tw-font-semibold d-flex ms-auto text-decoration-none tw-leading-[18px]"
        >
          Все
        </Link>
      </div>
      <div className="tw-divide-y tw-flex tw-flex-col">
        {loading ? (
          <Loader className="tw-mt-4 tw-mb-4" />
        ) : news.length > 0 ? (
          news.map((element) => (
            <div key={element.id} className="tw-pt-[12px] tw-pb-[10px]">
              <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-normal tw-mb-[2px]">
                {moment(element?.date, "DD-MM-YYYY").format("DD MMMM YYYY")}
              </p>
              <div className="tw-max-w-[221px] tw-truncate">
                <Link
                  to={"/changelog"}
                  className="tw-font-normal color-blue-main tw-text-[#0844B4] hover:tw-text-[#0A50D4] tw-text-base tw-leading-[18px]"
                >
                  {element?.title}
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-normal tw-mb-[2px]">
            Новостей пока нет
          </div>
        )}
      </div>
    </div>
  )
}