import Button from '../../button';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Field, Form, FormSpy } from 'react-final-form';
import Input from '../../input';
import classNames from 'classnames';

const inputSx = {
  '& .MuiInputBase-root': {
    textAlign: 'center !important',
    padding: 0,
    input: {
      padding: '0 !important',
      textAlign: 'center',
    },
    svg: {
      display: 'none',
    },
    fieldset: {
      border: 'none !important',
    },
  },
};

const validate = (values) => {
  const errors = {};

  const parsedAmount = Number(values?.amount);

  if (!values?.amount || !Number.isInteger(parsedAmount) || parsedAmount <= 0) {
    errors.amount = `Введите целочисленное значение больше 0`;
  }

  return errors;
};

export const AmountToggle = ({ handleChange, currentAmount, isSimplified }) => {
  const [amount, setAmount] = useState(currentAmount ?? 1);

  const [isDisabled, setIsDisabled] = useState(false);

  const incrementAmount = (inputAmount, form) => {
    const parsedAmount = Number(inputAmount);
    form.change('amount', parsedAmount + 1);
  };

  const decrementAmount = (inputAmount, form) => {
    const parsedAmount = Number(inputAmount);
    form.change('amount', parsedAmount - 1);
  };

  useEffect(() => {
    if (currentAmount !== amount) {
      setIsDisabled(false);
      setAmount(currentAmount);
    }
  }, [amount, currentAmount]);

  const onSubmit = useCallback(
    debounce((values, form) => {
      try {
        const parsedAmount = Number(values.amount);
        setIsDisabled(true);
        handleChange(parsedAmount);
      } catch (err) {
        form.reset();
      } finally {
        setIsDisabled(false);
      }
    }, 500),
    []
  );

  const errorStyle = classNames('tw-left-[-27px] tw-w-[310px] tw-p-0', {
    'tw-top-[23px]': !isSimplified,
    'tw-top-[15px]': isSimplified,
  })

  return (
    <Form
      onSubmit={(values, form) => onSubmit(values, form)}
      validate={validate}
      initialValuesEqual={() => {
        return amount === currentAmount;
      }}
      initialValues={{
        amount: currentAmount,
      }}
      render={({ form, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field name="amount">
              {({ input }) => (
                <div className="tw-flex tw-flex-row tw-items-center">
                  {!isSimplified && (
                    <Button
                      variant="text"
                      disabled={isDisabled}
                      className="tw-p-0 hover:tw-bg-transparent tw-w-fit"
                      onClick={() => decrementAmount(input.value, form)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <rect width="28" height="28" rx="4" fill="#F7F7F7" />
                        <path
                          d="M22.1615 14.1615C22.1615 14.7137 21.7137 15.1615 21.1615 15.1615H6.82812C6.27584 15.1615 5.82812 14.7137 5.82812 14.1615V13.8281C5.82812 13.2758 6.27584 12.8281 6.82812 12.8281H21.1615C21.7137 12.8281 22.1615 13.2758 22.1615 13.8281V14.1615Z"
                          fill="#0A50D4"
                        />
                      </svg>
                    </Button>
                  )}
                  <Input
                    disabled={isDisabled}
                    errorStyle={errorStyle}
                    type="number"
                    className="tw-w-[46px] tw-border-none tw-text-center tw-max-w-[46px]"
                    sx={inputSx}
                    {...input}
                  />
                  {!isSimplified && (
                    <Button
                      variant="text"
                      disabled={isDisabled}
                      className="tw-p-0 hover:tw-bg-transparent tw-w-fit"
                      onClick={() => incrementAmount(input.value, form)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <rect width="28" height="28" rx="4" fill="#F7F7F7" />
                        <path
                          d="M21 14C21 14.2228 20.9115 14.4364 20.754 14.594C20.5964 14.7515 20.3828 14.84 20.16 14.84H14.84V20.16C14.84 20.3828 14.7515 20.5964 14.594 20.754C14.4364 20.9115 14.2228 21 14 21C13.7772 21 13.5636 20.9115 13.406 20.754C13.2485 20.5964 13.16 20.3828 13.16 20.16V14.84H7.84C7.61722 14.84 7.40356 14.7515 7.24603 14.594C7.0885 14.4364 7 14.2228 7 14C7 13.7772 7.0885 13.5636 7.24603 13.406C7.40356 13.2485 7.61722 13.16 7.84 13.16H13.16V7.84C13.16 7.61722 13.2485 7.40356 13.406 7.24603C13.5636 7.0885 13.7772 7 14 7C14.2228 7 14.4364 7.0885 14.594 7.24603C14.7515 7.40356 14.84 7.61722 14.84 7.84V13.16H20.16C20.3828 13.16 20.5964 13.2485 20.754 13.406C20.9115 13.5636 21 13.7772 21 14Z"
                          fill="#0A50D4"
                        />
                      </svg>
                    </Button>
                  )}
                </div>
              )}
            </Field>
            <FormSpy
              subscription={{ values: true, errors: true, initialValues: true, pristine: true }}
              onChange={({ errors, pristine, values, initialValues }) => {
                if (Object.keys(errors).length === 0 && !pristine && initialValues?.amount !== values?.amount) {
                  handleSubmit();
                }
              }}
            />
          </form>
        );
      }}
    />
  );
};
