import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Button from '../../../components/button';
import { projectsAPIs } from '../../../services';
import { useDialog } from '../../../providers/dialog.provider';
import { useCallback } from 'react';
import { priceFormatter } from '../../../utils/priceFormatter';
import { ProjectsFormModal } from '../../projects/components/ProjectsFormModal';
import { getProjectStatus } from '../../../utils/common';
import { LinearProgress } from '@mui/material';
import { createErrorNotification } from '../../../utils/notifications';
import Loader from '../../../components/loader';
import { useSelector } from 'react-redux';

function CreateProjectForm() {
  const dialog = useDialog();
  const organizationId = useSelector((state) => state?.organizations?.selected?.id);
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);


  const handleOpenDialog = useCallback(() => {
    dialog.open(<ProjectsFormModal title="Создание проекта" value={{ name: '' }} onSubmit={onSubmit} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog]);

  const onSubmit = async (payload) => {
    const result = await projectsAPIs.createProject(payload);
    if (!result) {
      return { name: 'Такой проект уже существует' };
    }
    dialog.close();
    history.push(`/projects/${result.project.id}`);
  };
  useEffect(() => {
    if(organizationId) {
      projectsAPIs.getAllProjects(organizationId, 2, 1, '').then((res) => setProjects(res.data))
        .catch((err) => {
        createErrorNotification("Произошла ошибка при получении проектов");
        console.error(err);
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [organizationId]);

  return (
    <div
      className="tw-relative tw-z-20 tw-overflow-auto tw-pt-4 tw-p-6 tw-bg-white tw-rounded-xl widgetHeight"
    >
      <div className="tw-flex tw-flex-col tw-h-full">
        <div className="tw-flex tw-items-center">
          <h2 className={`tw-font-semibold tw-text-2xl tw-leading-[28px]`}>Последние проекты</h2>
          <Link
            to="/projects"
            className="tw-text-[#0844b4] hover:tw-text-[#0A50D4] tw-font-semibold d-flex ms-auto text-decoration-none tw-leading-[18px]"
          >
            Все
          </Link>
        </div>
        <div className="tw-flex tw-divide-y tw-flex-col tw-mb-2">
          {loading ? (
            <Loader className="tw-mt-4 tw-mb-4" />
          ) : projects.map((project) => {
            const progressValue = project?.product_count ? (project?.successed_product_count / project?.product_count) * 100 : 0;
            return (
              <>
                <div className="tw-flex tw-flex-col tw-gap-2 tw-py-4" key={project?.id}>
                  <div className="tw-flex tw-flex-row tw-gap-[0.5rem] tw-items-center">
                    <Link
                      to={`/projects/${project?.id}`}
                      className="tw-font-normal color-blue-main tw-text-[#0844B4] hover:tw-text-[#0A50D4] tw-text-base tw-max-w-[221px] tw-truncate  tw-leading-[18px]"
                    >
                      {project?.name}
                    </Link>
                    {getProjectStatus(project)}
                  </div>
                  <div className="tw-flex tw-flex-row tw-gap-5 tw-items-center">
                    <div className="tw-flex tw-flex-col tw-gap-[2px] tw-items-start ">
                      <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-normal">Итоговая цена</p>
                      <p className="tw-text-[#333] tw-text-base tw-leading-[18px] tw-font-normal">
                        {priceFormatter(project?.price)}
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-[2px] tw-items-start">
                      <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-normal">Логистика</p>
                      <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
                        {priceFormatter(project?.avg_cost_of_delivery)}
                      </p>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[6px] tw-items-start" style={{ paddingTop: "6px" }}>
                    <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-normal">
                      Найдено товаров <span
                      className="tw-text-[#333] tw-text-base tw-font-normal">{project?.successed_product_count}
                    </span> из <span
                      className="tw-text-[#333] tw-text-base tw-font-normal ">{project?.product_count}</span>
                    </p>
                    <LinearProgress
                      variant="determinate"
                      value={progressValue}
                      sx={{
                        width: "100%",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: progressValue === 100 ? "#49CA4E" : "#DD9F00",
                          borderRadius: "7px"
                        },
                        "&.MuiLinearProgress-root": {
                          backgroundColor: "#EEEEEE",
                          borderRadius: "7px"
                        }
                      }}
                    />
                  </div>
                </div>
              </>
            );
          })}
          {projects.length === 0 && (
            <div className="tw-text-[#666666] tw-text-base tw-leading-[20px] tw-font-normal tw-mt-5 tw-mb-2">
              У вас пока нет проектов
            </div>
          )}
        </div>

        <Button disabled={projects.length === 0} className="tw-mt-auto tw-rounded-xl bg-blue-main"
                onClick={handleOpenDialog}>
          Создать новый проект
        </Button>
      </div>
    </div>
  );
}

export default CreateProjectForm;
