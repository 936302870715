import GeneralLayout from '../../components/general-layout';
import { useCallback } from 'react';
import moment from 'moment';
import Button from '../../components/button';
import { ReactComponent as CheckIcon } from '../../icons/greenCheckIcon.svg';
import { useDialog } from '../../providers/dialog.provider';
import { RequestSettingsModal } from './RequestSettingModal';
import { Link, useParams } from 'react-router-dom';
import { useGetSupportAnswersChatQuery, useGetSupportAnswersRequestQuery } from '../../services/supportAPIs';
import { supportRequestStatusFormatter } from '../../utils/common';
import { SupportAnswer, SupportAnswersChat } from '../support-answers/components/SupportAnswersChat';
import classNames from 'classnames';

export const DetailedRequest = () => {
  const { id } = useParams();
  const dialog = useDialog();
  const { data: support_request } = useGetSupportAnswersRequestQuery(id, {
    skip: !id,
  });

  const { data: chat } = useGetSupportAnswersChatQuery(id, {
    skip: !id,
  });

  const getCorrectDate = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY');
    } else return '-';
  };
  const getCorrectProject = (entity) => {
    if (entity?.project)
      return (
        <>
          <Link
            className="tw-text-[#0844B4] tw-leading-[18px] hover:tw-text-[#0A50D4] tw-cursor-pointer"
            to={`/projects/${entity?.project_id}`}
          >
            {entity?.project}
          </Link>
        </>
      );
    return (
      <>
        <p>-</p>
      </>
    );
  };

  const getCorrectStatus = () => {
    if (support_request?.status === 'Принято к сведению') return 'accepted';
    if (support_request?.status === 'В очереди на обработку') return 'in_queue';

    return support_request?.status ?? '';
  };

  const getCorrectPriority = () => {
    return support_request?.criticality ?? '';
  };

  const openSettingModal = useCallback(() => {
    dialog.open(
      <RequestSettingsModal
        currentPriority={getCorrectPriority()}
        currentStatus={getCorrectStatus()}
        request_id={support_request?.id}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, support_request]);

  const isTicketOpen = support_request?.status !== 'Исполнено' && support_request?.status !== 'completed';

  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col tw-h-full">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-[10px]  tw-mb-8">
          <h2 className="tw-text-[#191919] tw-leading-[38px] tw-font-semibold tw-text-[32px]">Обращение</h2>
          {supportRequestStatusFormatter(support_request?.status)}
        </div>
        <div className="tw-flex tw-flex-col tw-bg-white tw-p-6 tw-gap-[20px] tw-rounded-lg">
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Проект</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">
              {getCorrectProject(support_request)}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Имя</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">{support_request?.name}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Email</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">{support_request?.email}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Компания</p>
            <p className="tw-text-[#0A50D4] tw-leading-[18px] tw-text-sm tw-font-medium">
              {support_request?.organization}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Дата создания</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">
              {getCorrectDate(support_request?.created_at)}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
            <p className="tw-text-[#666666] tw-leading-[18px] tw-text-sm">Срок</p>
            <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-medium">
              {getCorrectDate(support_request?.deadline)}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-items-start">
            <p className="tw-text-[#191919] tw-font-medium tw-leading-5 tw-mb-1">Обращение</p>
            <div
              className={classNames('tw-flex tw-flex-col tw-w-full tw-max-w-[916px]', {
                'tw-divide-y tw-divide-y-[#D5D5D5]': chat?.data?.length > 0,
              })}
            >
              <SupportAnswer text={support_request?.description} date={support_request?.created_at} />
              <SupportAnswersChat chat={chat?.data} isTicketClosed={!isTicketOpen} isAdmin />
            </div>
          </div>

          {isTicketOpen ? (
            <div className="tw-flex tw-flex-row tw-gap-4 tw-mt-3">
              <Button
                className="tw-w-fit tw-text-sm tw-leading-[18px] tw-font-semibold tw-text-center tw-rounded-lg"
                onClick={() => openSettingModal()}
              >
                Решение по заявке
              </Button>
            </div>
          ) : (
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
              {' '}
              <CheckIcon />{' '}
              <p className="tw-text-sm tw-leading-[18px] tw-text-[#0BD90B] tw-font-semibold">Заявка выполнена</p>
            </div>
          )}
        </div>
      </div>
    </GeneralLayout>
  );
};
