import { Form } from 'react-final-form';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import { useAddProductMutation } from '../../../../services';
import styles from './addProduct.module.sass';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const invalidCharsRegex = /[^a-zA-Zа-яёА-ЯЁ0-9\s,.%:;()"'-]/g;

export const AddProduct = () => {
  const [addProduct, { isLoading }] = useAddProductMutation();

  const report = useSelector((state) => state?.organizations?.selectedReport);

  const validate = (values) => {
    const errors = {};
    const invalidChars = values?.name?.match(invalidCharsRegex);
    if (values.name?.length > 600) {
      errors.name = 'Название должно содержать не более 600 символов';
    }
    if (invalidChars?.length > 0) {
      const uniqueInvalidCharts = [...new Set(invalidChars)];
      errors.name = `Название содержит недопустимые символы: ${uniqueInvalidCharts.join(',')}`;
    }

    if (values?.amount) {
      const parsedAmount = Number(values.amount)
      if (!Number.isInteger(parsedAmount) || parsedAmount < 0) {
        errors.amount = 'Введите целочисленное значение больше 0';
      }
    }

    return errors;
  };
  const handleAddProduct = async ({ name, amount }, form) => {
    const correctName = name.replace(/\s+/g, ' ').trim();
    await addProduct({
      report_id: report?.id,
      body: {
        name: correctName,
        amount: Number(amount),
        unit: 'шт',
      },
    })
      .unwrap()
      .then(() => {
        form.restart();
      });
  };

  return (
    <Form validate={validate}  onSubmit={({ name, amount }, form) => handleAddProduct({ name, amount }, form)}>
      {({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit} className={`${styles.form} tw-flex tw-flex-col tw-items-start tw-gap-4 `}>
          <div
            className={classNames('tw-flex tw-flex-row  tw-w-full tw-bg-white tw-rounded-lg', {
              'tw-mb-[11px]': invalid,
            })}
          >
            <Input
              className={`${styles.input} tw-w-full tw-rounded-lg`}
              name="name"
              placeholder="Введите название продукта как можно точнее"
              id="productNameInput"
              required
            />
            <div className={`tw-flex tw-flex-row ${styles.bordered_container} tw-relative`}>
              <Input
                type="number"
                min={0}
                errorStyle={'tw-w-[310px] tw-bottom-[-25px] tw-left-[-81px]'}
                className={styles.input}
                name="amount"
                required
                placeholder="Количество"
                id="productAmountInput"
              />
              <Button
                type="submit"
                className="tw-max-w-[fit-content] tw-rounded-lg tw-bg-[#134B98] disabled:tw-bg-[#8CA6C1] tw-transition-colors"
                disabled={isLoading || invalid || pristine}
              >
                Добавить
              </Button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
