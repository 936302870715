import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Button from '../../button';
import { supportAPIs } from '../../../services/supportAPIs';
import { statusesSupport } from './StatusesForTechWidget';
import { createErrorNotification } from '../../../utils/notifications';
import Loader from '../../loader';

export default function TechSupportWidget(){
  const [techSupportTickets, setTechSupportTickets] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    supportAPIs.getRequestsOfSupport(2, 1, '').then((res) => {
      setTechSupportTickets(res)
    }).catch((err) => {
      createErrorNotification("Произошла ошибка при получении заявок тех.поддержки");
      console.error(err);
    }).finally(() => {
      setLoading(false)
    });
  }, []);
  return (
    <div
      className="tw-relative tw-z-20 tw-overflow-auto tw-pt-4 tw-p-6 tw-bg-white tw-rounded-xl widgetHeight"
    >
      <div className="tw-flex tw-flex-col tw-h-full">
        <div className="tw-flex tw-items-center">
          <h2 className="tw-font-semibold tw-text-2xl tw-leading-[28px]">Тех. поддержка</h2>
          <Link
            to="/"
            style={{ cursor: "not-allowed" }}
            className="tw-text-[#0844b4] hover:tw-text-[#0A50D4] tw-font-semibold d-flex ms-auto text-decoration-none tw-leading-[18px]"
          >
            Все
          </Link>
        </div>

        <div className="tw-flex tw-flex-col tw-divide-y">
          {loading ? (
            <Loader className="tw-mt-4 tw-mb-4" />
          ) : (
            techSupportTickets?.data?.length > 0 && techSupportTickets?.data?.map((elem) => (
              <div className="tw-py-4">
                <div className="tw-flex tw-flex-col ">
                  <div className="tw-mb-2">
                    <p
                      className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-normal tw-mb-[2px]">Проект</p>
                    <p className="tw-text-base tw-font-normal">{elem?.project}</p>
                  </div>
                  <div className="tw-flex tw-flex-col tw-items-start">
                    <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-normal tw-mb-[2px]">
                      Статус
                    </p>
                    <p>{statusesSupport[elem?.status] ?? <p>Отсутствует</p>}</p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        {techSupportTickets?.data?.length === 0 && (
          <div className="tw-text-[#666666] tw-text-base tw-leading-[20px] tw-font-normal tw-mt-5 tw-mb-2">
            У вас нет обращений в тех. поддержку
          </div>
        )}

        <div className="tw-flex tw-flex-col tw-gap-4 tw-mt-auto">
          <Link to={"/technical-support"}>
            <Button className="tw-mt-auto tw-rounded-xl bg-blue-main">
              Написать в тех. поддержку
            </Button>
          </Link>
          <Link to={"/faq"}>
            <Button
              variant="outlined"
              className="tw-mt-auto tw-rounded-xl"
            >
              Часто задаваемые вопросы
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}