import { Container } from 'react-bootstrap';
import { ReactComponent as PhoneIcon } from '../../icons/phoneIcon.svg';
import { ReactComponent as MailIcon } from '../../icons/mailIcon.svg';
import { ReactComponent as WhiteLogo } from '../../icons/whiteLogo.svg';
import { ReactComponent as DepartmentLogo } from '../../icons/departmentLogo.svg';
import classes from './footer.module.sass';
import React, { useCallback } from 'react';
import { ShowQR } from '../../pages/landing/components/modal/ShowQR';
import { useDialog } from '../../providers/dialog.provider';

export default function Footer() {
  const dialog = useDialog();

  const showLargerQR = useCallback(() => {
    dialog.open(<ShowQR />)
  }, [dialog]);

  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer} style={{
      backgroundImage: 'url(/images/background/footerBg.png)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}
    >
      <Container className={classes.container}>
        <div className="tw-flex tw-gap-[20px]">
          <WhiteLogo />
          <div className="separator"></div>
          <DepartmentLogo />
        </div>

        <div>
          <div className="tw-flex tw-gap-[26px]">
            <div className="tw-flex tw-flex-col tw-justify-center">
              <a href="tel:8(843)236-60-01" className="tw-flex tw-items-center">
                <PhoneIcon className="tw-mr-1" />
                <p className="tw-text-[#FFF] tw-text-sm tw-font-normal">8 (843) 236-60-01</p>
              </a>
              <a href="mailto:predictiveanalytics@yanao.ru" className="tw-flex tw-items-center">
                <MailIcon className="tw-mr-1" />
                <p className="tw-text-[#FFF] tw-text-sm tw-font-normal">predictiveanalytics@yanao.ru</p>
              </a>
            </div>
            <img src="/images/telegram.png" className="tw-cursor-pointer" onClick={showLargerQR} alt="telegramQR" />
            <div className="tw-flex tw-items-center">
              <p className="tw-text-[#FFF] tw-text-sm tw-font-normal">© 2020-{currentYear} Сервис Предиктивной Аналитики</p>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
