import { useCallback, useEffect, useMemo, useState } from 'react';
// import { ReactComponent as RestartIcon } from '../../../../icons/restart.svg';
// import { ReactComponent as MailIcon } from '../../../../icons/sendMessageIcon.svg';
import { useDialog } from '../../../../providers/dialog.provider';
import { ConfirmModal } from '../../../../components/modal/ConfirmModal';
import classNames from 'classnames';
import { convertStringToURL } from '../../../../utils/common';
import { SourceCheckbox } from './SourceCheckbox';
import { SourceLogisticInfo } from './SourceLogisticInfo';
import { SourceInfo } from './SourceInfo';

export const Source = ({
  source,
  fields,
  asTable,
  onDeleteSource,
  onSaveSource,
  viewDetailedInfo,
  onCorrectingPrice,
  onBlockSource,
}) => {
  const [isSelected, setIsSelected] = useState(source?.selected);
  const dialog = useDialog();

  const validUrl = convertStringToURL(source?.url);

  useEffect(() => {
    setIsSelected(source?.selected);
  }, [source?.selected]);

  const minValidLogisticPrice = useMemo(
    () =>
      source?.delivery_prices
        .filter((item) => item.price !== null && item.price !== 0)
        .sort((a, b) => a.price - b.price)?.[0]?.price,
    [source?.delivery_prices]
  );

  // const handleCreateChatWithSupplier = async () => {
  // TODO: Нет endpoint-а для создания чата, да и возможности видимо тоже
  // };

  const handleOpenCorrectingPriceModal = () => {
    onCorrectingPrice(source);
  };

  const handleDeleteSource = useCallback(() => {
    dialog.open(
      <ConfirmModal
        value={source}
        onAgree={onDeleteSource}
        title={`Вы действительно хотите удалить источник ${validUrl?.hostname}?`}
      />
    );
  }, [dialog, onDeleteSource, source, validUrl.hostname]);

  const selectedSourceBgColor = asTable ? '#F9FCFF' : 'tw-bg-[#F3F9FF]';

  const handleBlockAndDeleteSource = useCallback(() => {
    onBlockSource(source?.id, source?.url);
  }, [onBlockSource, source?.id, source?.url]);

  const showBlockAndDeleteConfirmation = useCallback(() => {
    dialog.open(
      <ConfirmModal
        onAgree={handleBlockAndDeleteSource}
        title={'Вы действительно хотите удалить и заблокировать источник?'}
      />
    );
  }, [dialog, handleBlockAndDeleteSource]);

  const handleChangeCheckboxGroup = (e, option) => {
    try {
      if (e.target.checked) fields.push(option.id);
      else fields.remove(option.id);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCheckboxChange = (e, option) => {
    const newSelected = e.target.checked;
    setIsSelected(newSelected);

    try {
      handleChangeCheckboxGroup(e, option);
    } catch (error) {
      setIsSelected(!newSelected);
      console.error('Error updating selected:', error);
    }
  };

  const handleViewLogisticDetailed = () => {
    viewDetailedInfo(source);
  };
  const handleSaveSource = () => {
    onSaveSource(source);
  };
  return (
    <>
      <div
        className={classNames('tw-w-full  tw-transition-colors tw-px-4 ', {
          [selectedSourceBgColor]: isSelected,
          'tw-bg-white': !isSelected,
        })}
      >
        <div
          className={classNames('tw-flex tw-flex-row tw-items-start tw-border-t tw-border-[#DDDDDD]', {
            'tw-pt-[14px] tw-pb-[11px]': asTable,
            'tw-pt-[17px] tw-pb-[14px]': !asTable,
          })}
        >
          <SourceCheckbox option={source} handleChange={handleCheckboxChange} isSelected={isSelected} />
          <SourceInfo
            asTable={asTable}
            sourceImg={source?.pics?.[0]}
            url={validUrl?.href}
            hostname={validUrl?.hostname}
            onDeleteSource={handleDeleteSource}
            onSaveSource={handleSaveSource}
            onBlockSource={showBlockAndDeleteConfirmation}
            title={source?.title}
            sourceScreenshot={source?.screenshot}
          />

          <div className="tw-flex tw-flex-col tw-gap-2 tw-ml-4 tw-w-[20%]">
            <SourceLogisticInfo
              asTable={asTable}
              productPrice={source?.price}
              onCorrectPrice={handleOpenCorrectingPriceModal}
              isDimensionsCalculationFalied={source?.failed_to_calculate_dimensions}
              logisticPrice={minValidLogisticPrice}
              handleViewDetailedLogisticInfo={handleViewLogisticDetailed}
            />
          </div>
        </div>
      </div>
    </>
  );
};
