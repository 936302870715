import { useEffect, useState } from 'react';
import Button from '../../../../../components/button';
import { ImageWithFallback } from '../../../../../components/imageWithFallback';
import { AmountToggle } from '../../../../../components/product-card/components/AmountToggle';
import { placeholderImages } from '../../../../../components/product-card/placeholderImage';
import { ReactComponent as ReloadIcon } from '../../../../../icons/restart.svg';
import { ReactComponent as CheckIcon } from '../../../../../icons/greenCheckIcon.svg';
import { ChangeUnit } from '../../ChangeUnit/ChangeUnit';
import styles from '../productTable.module.sass';

export const ProductOverview = ({
  onUpdateProduct,
  product,
  isProductLoading,
  onChangeAmount,
  handleReloadProduct,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const loadingPlaceholderImage = placeholderImages[product?.category] ?? '/images/placeholders/other.png';

  const productImgSrc = isLoading ? loadingPlaceholderImage : product?.pics?.[0];

  useEffect(() => {
    setIsLoading(isProductLoading);
  }, [isProductLoading]);
  return (
    <>
      <div className="tw-flex tw-flex-row tw-p-4 tw-w-[70%] tw-relative">
        <Button
          variant="text"
          onClick={handleReloadProduct}
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-absolute tw-top-[10px] tw-right-[6px]"
        >
          <ReloadIcon className="tw-fill-[#134B98] tw-h-[28px] tw-w-[28px]" />
        </Button>
        <ImageWithFallback
          srcImg={productImgSrc}
          fallbackSrc={'/images/placeholders/other.png'}
          className="tw-min-w-[120px] tw-min-h-[120px] tw-max-h-[120px] tw-max-w-[120px] tw-rounded-[4px] tw-aspect-square"
          loading="lazy"
          alt="product_img"
        />
        <div className="tw-flex-tw-flex-col tw-w-full tw-ml-[14px] tw-gap-3">
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-max-w-[379px]">
            <p className={`tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-semibold ${styles.productName}`}>
              {product?.name}
            </p>
            {product?.use_gpt && (
                <div className='tw-bg-[#EEFCEE] tw-flex tw-flex-row tw-gap-[2px] tw-items-center tw-py-1 tw-px-2 tw-rounded-3xl'>
                  <CheckIcon />
                <p className='tw-text-[#49CA4E] tw-text-[12px] tw-leading-4 tw-font-medium'>Применён оптимизатор названий</p>
              </div>
            )}
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-mt-1">
              <AmountToggle currentAmount={product?.amount} handleChange={onChangeAmount} />

              <ChangeUnit currentUnit={product?.unit} onChange={onUpdateProduct} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
