import { Checkbox } from '@mui/material';
import { ReactComponent as StarIcon } from '../../../../icons/starIcon.svg';

export const SourceCheckbox = ({ option, handleChange, isSelected }) => {
  return (
    <>
      <Checkbox
        value={option}
        onChange={(e) => handleChange(e, option)}
        className="tw-p-0 tw-mr-2 tw-self-start"
        checked={isSelected}
        icon={<StarIcon className="tw-fill-[#CCCCCC]" />}
        checkedIcon={<StarIcon className="tw-fill-[#DD9F00]" />}
      />
    </>
  );
};
