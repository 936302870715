import React, { useCallback } from 'react';
import { ShowQR } from '../../../pages/landing/components/modal/ShowQR';
import { useDialog } from '../../../providers/dialog.provider';
import { MainGuide } from '../../user-guide/MainGuide';
import { LogisticGuide } from '../../user-guide/LogisticGuide';


export default function InstructionsWidget(){
  const dialog = useDialog();

  const handleOpenMainGuide = useCallback(() => {
    dialog.open(<MainGuide />);
  }, [dialog]);

  const handleOpenLogisticGuide = useCallback(() => {
    dialog.open(<LogisticGuide />);
  }, [dialog]);

  const showLargerQR = useCallback(() => {
    dialog.open(<ShowQR />)
  }, [dialog]);

  return(
    <div className="tw-relative tw-z-20 tw-overflow-hidden tw-pt-4 tw-px-[24px] tw-bg-white tw-rounded-xl widgetHeight"
         style={{
           backgroundImage: 'url(/images/background/bg-red-template.png)',
           backgroundRepeat: 'no-repeat',
           backgroundSize: 'cover',
           paddingBottom: '126px',
    }}
    >
      <p className="tw-font-semibold tw-text-2xl tw-leading-[28px] tw-text-[#FFF] tw-mb-[20px]">Инструкции</p>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-6">
        <p className="tw-leading-[20px] tw-text-[#FFF] tw-text-base text-decoration-underline tw-cursor-pointer"
           onClick={handleOpenMainGuide}
        >
          Руководство по работе с сервисом<br /> предиктивной аналитики
        </p>
        <p className="tw-leading-[28px] tw-text-[#FFF] text-decoration-underline tw-cursor-pointer"
           onClick={handleOpenLogisticGuide}
        >Руководство по логистике</p>
        <a href='https://t.me/+tOwVMmIWThhkYWVi'
           className="tw-cursor-pointer text-decoration-underline tw-leading-5 hover:tw-text-white tw-text-[#FFF]"
           target='_blank' rel='noreferrer'>
          Присоединиться к чату в Telegram
        </a>
      </div>
      <img src="/images/tgQRcode.png" className="tw-cursor-pointer" onClick={showLargerQR} alt="telegramQR" />
    </div>
  )
}