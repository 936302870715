import { useEffect } from 'react';

export const useScrollRestoration = (dependencies = []) => {
  useEffect(() => {
    const topEl = document.getElementById('top');
    if (topEl) {
      topEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, dependencies);
};
